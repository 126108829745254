// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PAYMENT_TERMS = "UPDATE_PAYMENT_TERMS";
export const UPDATE_PAYMENT_TERMS_SUCCESS = "UPDATE_PAYMENT_TERMS_SUCCESS";
export const IDENTIFIED_USER = "IDENTIFIED_USER";
export const IDENTIFIED_USER_SUCCES = "IDENTIFIED_USER_SUCCESS";
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ACCOUNTANT_USERS = "GET_ACCOUNTANT_USERS";
export const GET_ACCOUNTANT_USERS_SUCCESS = "GET_ACCOUNTANT_USERS_SUCCESS";

// ACCOUNTANT
export const ASSIGN_ACCOUNTANT = "ASSIGN_ACCOUNTANT";
export const ASSIGN_ACCOUNTANT_SUCCESS = "ASSIGN_ACCOUNTANT_SUCCESS";

//Sticky
export const GET_STICKY = "GET_STICKY";
export const NOTES_UPDATE = "NOTES_UPDATE";
export const FETCH_ALL_NOTES_SUCCESS = "FETCH_ALL_NOTES_SUCCESS";
export const UPDATE_ALL_NOTES_SUCCESS = "UPDATE_ALL_NOTES_SUCCESS";

//FARMER
export const ADD_FARMER = "ADD_FARMER";
export const ADD_FARMER_SUCCESS = "ADD_FARMER_SUCCESS";
export const GET_ALL_FARMERS = "GET_ALL_FARMERS";
export const GET_ALL_FARMERS_SUCCESS = "GET_ALL_FARMERS_SUCCESS";
export const GET_FARMER = "GET_FARMER";
export const GET_FARMER_SUCCESS = "GET_FARMER_SUCCESS";
export const UPDATE_FARMER = "UPDATE_FARMER";
export const UPDATE_FARMER_SUCCESS = "UPDATE_FARMER_SUCCESS";
export const SELECT_ALL_FARMERS = "SELECT_ALL_FARMERS";
export const SELECT_SINGLE_FARMER = "SELECT_SINGLE_FARMER";
export const DELETE_FARMERS = "DELETE_FARMERS";
export const DELETE_FARMERS_SUCCESS = "DELETE_FARMERS_SUCCESS";

//TAX_CLASS
export const ADD_TAX_CLASS = "ADD_TAX_CLASS";
export const ADD_TAX_CLASS_SUCCESS = "ADD_TAX_CLASS_SUCCESS";
export const GET_ALL_TAX_CLASSES = "GET_ALL_TAX_CLASSES";
export const GET_ALL_TAX_CLASSES_SUCCESS = "GET_ALL_TAX_CLASSES_SUCCESS";
export const GET_TAX_CLASS = "GET_TAX_CLASS";
export const GET_TAX_CLASS_SUCCESS = "GET_TAX_CLASS_SUCCESS";
export const UPDATE_TAX_CLASS = "UPDATE_TAX_CLASS";
export const UPDATE_TAX_CLASS_SUCCESS = "UPDATE_TAX_CLASS_SUCCESS";
export const SELECT_ALL_TAX_CLASSES = "SELECT_ALL_TAX_CLASSES";
export const SELECT_SINGLE_TAX_CLASS = "SELECT_SINGLE_TAX_CLASS";
export const DELETE_TAX_CLASSES = "DELETE_TAX_CLASSES";
export const DELETE_TAX_CLASSES_SUCCESS = "DELETE_TAX_CLASSES_SUCCESS";
export const GET_ALL_TAXES_CLASSES = "GET_ALL_TAXES_CLASSES";
export const GET_ALL_TAXES_CLASSES_SUCCESS = "GET_ALL_TAXES_CLASSES_SUCCESS";
//ENTERPRISE
export const ADD_ENTERPRISE = "ADD_ENTERPRISE";
export const ADD_ENTERPRISE_SUCCESS = "ADD_ENTERPRISE_SUCCESS";
export const GET_ALL_ENTERPRISES = "GET_ALL_ENTERPRISES";
export const GET_ALL_ENTERPRISES_SUCCESS = "GET_ALL_ENTERPRISES_SUCCESS";
export const GET_ALL_PROVIDER = "GET_ALL_PROVIDER";
export const GET_ALL_PROVIDER_SUCCESS = "GET_ALL_PROVIDER_SUCCESS";
export const GET_ENTERPRISE = "GET_ENTERPRISE";
export const GET_ENTERPRISE_SUCCESS = "GET_ENTERPRISE_SUCCESS";
export const UPDATE_ENTERPRISE = "UPDATE_ENTERPRISE";
export const UPDATE_ENTERPRISE_SUCCESS = "UPDATE_ENTERPRISE_SUCCESS";
export const SELECT_ALL_ENTERPRISES = "SELECT_ALL_ENTERPRISES";
export const SELECT_SINGLE_ENTERPRISE = "SELECT_SINGLE_ENTERPRISE";
export const DELETE_ENTERPRISES = "DELETE_ENTERPRISES";
export const DELETE_ENTERPRISES_SUCCESS = "DELETE_ENTERPRISES_SUCCESS";
export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const GET_ALL_CUSTOMERS_SUCCESS = "GET_ALL_CUSTOMERS_SUCCESS";

//INVOICES
export const ADD_INVOICE = "ADD_INVOICE";
export const ADD_INVOICE_SUCCESS = "ADD_INVOICE_SUCCESS";
export const ADD_INVOICE_FROM_DELIVERY_NOTE = "ADD_INVOICE_FROM_DELIVERY_NOTE";
export const ADD_INVOICE_FROM_DELIVERY_NOTE_SUCCESS = "ADD_INVOICE_FROM_DELIVERY_NOTE_SUCCESS";
export const ADD_DELIVERY_NOTE_SUCCESS = "ADD_DELIVERY_NOTE_SUCCESS";
export const TRANSFERT_QUOTATION_TO_INVOICE = "TRANSFERT_QUOTATION_TO_INVOICE";
export const TRANSFERT_QUOTATION_TO_INVOICE_SUCCESS =
  "TRANSFERT_QUOTATION_TO_INVOICE_SUCCESS";
export const DUPLICATE_INVOICE = "DUPLICATE_INVOICE";
export const DUPLICATE_INVOICE_SUCCESS = "DUPLICATE_INVOICE_SUCCESS";
export const GET_ALL_INVOICE = "GET_ALL_INVOICE";
export const GET_ALL_INVOICE_SUCCESS = "GET_ALL_INVOICE_SUCCESS";
export const GET_ALL_DELIVERY_NOTE = "GET_ALL_DELIVERY_NOTE";
export const GET_ALL_DELIVERY_NOTE_SUCCESS = "GET_ALL_DELIVERY_NOTE_SUCCESS";
export const GET_ALL_MONTHLY_INVOICE = "GET_ALL_MONTHLY_INVOICE";
export const GET_ALL_MONTHLY_INVOICE_SUCCESS =
  "GET_ALL_MONTHLY_INVOICE_SUCCESS";
export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS";
export const UPDATE_META_INVOICE = "UPDATE_META_INVOICE";
export const UPDATE_META_INVOICE_SUCCESS = "UPDATE_META_INVOICE_SUCCESS";
export const SELECT_ALL_INVOICES = "SELECT_ALL_INVOICES";
export const SELECT_SINGLE_INVOICE = "SELECT_SINGLE_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const PAYE_INVOICE = "PAYE_INVOICE";
export const PAYE_INVOICE_SUCCESS = "PAYS_INVOICE_SUCCES";
export const DOWNLOAD_INVOICE = "DOWNLOAD_INVOICE";
export const DOWNLOAD_INVOICE_SUCCESS = "DOWNLOAD_INVOICE_SUCCESS";
export const SEND_MAIL_INVOICE = "SEND_MAIL_INVOICE";
export const SEND_MAIL_INVOICE_SUCCESS = "SEND_MAIL_INVOICE_SUCCESS";
export const FETECH_SALES_REVENUE = "FETECH_SALES_REVENUE";
export const FETECH_SALES_REVENUE_SUCCESS = "FETECH_SALES_REVENUE_SUCCESS"; 
export const GET_INVOICES_REFERENCES = "GET_INVOICES_REFERENCES";
export const GET_INVOICES_REFERENCES_SUCCESS = "GET_INVOICES_REFERENCES_SUCCESS";

//Monthly Reports
export const ADD_MONTHLY_REPORT = "ADD_MONTHLY_REPORT";
export const ADD_MONTHLY_REPORT_SUCCESS = "ADD_MONTHLY_REPORT_SUCCESS";
export const GET_ALL_MONTHLY_REPORT = "GET_ALL_MONTHLY_REPORT";
export const GET_ALL_MONTHLY_REPORT_SUCCESS = "GET_ALL_MONTHLY_REPORT_SUCCESS";
export const DOWNLOAD_MONTHLY_REPORT = "DOWNLOAD_MONTHLY_REPORT";
export const DOWNLOAD_MONTHLY_REPORT_SUCCESS = "DOWNLOAD_MONTHLY_REPORT_SUCCESS";
export const DELETE_MONTHLY_REPORT = "DELETE_MONTHLY_REPORT";
export const DELETE_MONTHLY_REPORT_SUCCESS = "DELETE_MONTHLY_REPORT_SUCCESS";
export const GET_MONTHLY_REPORT = "GET_MONTHLY_REPORT";
export const GET_MONTHLY_REPORT_SUCCESS = "GET_MONTHLY_REPORT_SUCCESS";
export const UPDATE_MONTHLY_REPORT = "UPDATE_MONTHLY_REPORT";
export const UPDATE_MONTHLY_REPORT_SUCCESS = "UPDATE_MONTHLY_REPORT_SUCCESS";

//Edit Modal
export const SHOW_EDIT_MODAL_INVOICE = "SHOW_EDIT_MODAL_INVOICE";
export const HIDE_EDIT_MODAL_INVOICE = "HIDE_EDIT_MODAL_INVOICE";

//Quotations
export const ADD_QUOTATION = "ADD_QUOTATION";
export const ADD_QUOTATION_SUCCESS = "ADD_QUOTATION_SUCCESS";
export const DUPLICATE_QUOTATION = "DUPLICATE_QUOTATION";
export const DUPLICATE_QUOTATION_SUCCESS = "DUPLICATE_QUOTATION_SUCCESS";
export const GET_ALL_QUOTATION = "GET_ALL_QUOTATION";
export const GET_ALL_QUOTATION_SUCCESS = "GET_ALL_QUOTATION_SUCCESS";
export const GET_QUOTATION = "GET_QUOTATION";
export const GET_QUOTATION_SUCCESS = "GET_QUOTATION_SUCCESS";
export const UPDATE_QUOTATION = "UPDATE_QUOTATION";
export const UPDATE_QUOTATION_SUCCESS = "UPDATE_QUOTATION_SUCCESS";
export const SELECT_ALL_QUOTATIONS = "SELECT_ALL_QUOTATIONS";
export const SELECT_SINGLE_QUOTATION = "SELECT_SINGLE_QUOTATION";
export const DELETE_QUOTATION = "DELETE_QUOTATION";
export const DELETE_QUOTATION_SUCCESS = "DELETE_QUOTATION_SUCCESS";
export const DOWNLOAD_QUOTATION = "DOWNLOAD_QUOTATION";
export const DOWNLOAD_QUOTATION_SUCCESS = "DOWNLOAD_QUOTATION_SUCCESS";
export const DELETE_QUOTATION_ITEM = "DELETE_QUOTATION_ITEM";
export const DELETE_QUOTATION_ITEM_SUCCESS = "DELETE_QUOTATION_ITEM_SUCCESS";
export const ADD_QUOTATION_ITEM = "ADD_QUOTATION_ITEM";
export const ADD_QUOTATION_ITEM_SUCCESS = "ADD_QUOTATION_ITEM_SUCCESS";
export const SEND_MAIL_QUOTATION = "SEND_MAIL_QUOTATION";
export const SEND_MAIL_QUOTATION_SUCCESS = "SEND_MAIL_QUOTATION_SUCCESS";
export const GET_QUOTATION_REFERENCE = "GET_QUOTATION_REFERENCE";
export const GET_QUOTATION_REFERENCE_SUCCESS = "GET_QUOTATION_REFERENCE_SUCCESS";

//FARMERS_PRODUCTS
export const ADD_FARMERS_PRODUCTS = "ADD_FARMERS_PRODUCTS";
export const ADD_FARMERS_PRODUCTS_SUCCESS = "ADD_FARMERS_PRODUCTS_SUCCESS";
export const GET_ALL_FARMERS_PRODUCTS = "GET_ALL_FARMERS_PRODUCTS";
export const GET_ALL_FARMERS_PRODUCTS_SUCCESS =
  "GET_ALL_FARMERS_PRODUCTS_SUCCESS";
export const GET_FARMERS_PRODUCTS = "GET_FARMERS_PRODUCTS";
export const GET_FARMERS_PRODUCTS_SUCCESS = "GET_FARMERS_PRODUCTS_SUCCESS";
export const UPDATE_FARMERS_PRODUCTS = "UPDATE_FARMERS_PRODUCTS";
export const UPDATE_FARMERS_PRODUCTS_SUCCESS =
  "UPDATE_FARMERS_PRODUCTS_SUCCESS";
export const SELECT_ALL_FARMERS_PRODUCTS = "SELECT_ALL_FARMERS_PRODUCTS";
export const SELECT_SINGLE_FARMERS_PRODUCTS = "SELECT_SINGLE_FARMERS_PRODUCTS";
export const DELETE_FARMERS_PRODUCTS = "DELETE_FARMERS_PRODUCTS";
export const DELETE_FARMERS_PRODUCTS_SUCCESS =
  "DELETE_FARMERS_PRODUCTS_SUCCESS";

//PRODUCT
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const SELECT_ALL_PRODUCTS = "SELECT_ALL_PRODUCTS";
export const SELECT_SINGLE_PRODUCT = "SELECT_SINGLE_PRODUCT";
export const DELETE_PRODUCTS = "DELETE_PRODUCTS";
export const DELETE_PRODUCTS_SUCCESS = "DELETE_PRODUCTS_SUCCESS";

//UNITY PRODUCT
export const ADD_UNITY = "ADD_UNITY";
export const ADD_UNITY_SUCCESS = "ADD_UNITY_SUCCESS";
export const GET_ALL_UNITIES = "GET_ALL_UNITIES";
export const GET_ALL_UNITIES_SUCCESS = "GET_ALL_UNITIES_SUCCESS";
export const UPDATE_UNITY = "UPDATE_UNITY";
export const UPDATE_UNITY_SUCCESS = "UPDATE_UNITY_SUCCESS";
export const DELETE_UNITY = "DELETE_UNITY";
export const DELETE_UNITY_SUCCESS = "DELETE_UNITY_SUCCESS";

//BankingTransaction
export const ADD_BANKING_TRANSACTION = "ADD_BANKING_TRANSACTION";
export const ADD_BANKING_TRANSACTION_SUCCESS =
  "ADD_BANKING_TRANSACTION_SUCCESS";
export const GET_ALL_BANKING_TRANSACTION = "GET_ALL_BANKING_TRANSACTION";
export const GET_ALL_BANKING_TRANSACTION_SUCCESS =
  "GET_ALL_BANKING_TRANSACTION_SUCCESS";
export const GET_ALL_MONTHLY_BANKING_TRANSACTION =
  "GET_ALL_MONTHLY_BANKING_TRANSACTION";
export const GET_ALL_MONTHLY_BANKING_TRANSACTION_SUCCESS =
  "GET_ALL_MONTHLY_BANKING_TRANSACTION_SUCCESS";
export const GET_BANKING_TRANSACTION = "GET_BANKING_TRANSACTION";
export const GET_BANKING_TRANSACTION_SUCCESS =
  "GET_BANKING_TRANSACTION_SUCCESS";
export const UPDATE_BANKING_TRANSACTION = "UPDATE_BANKING_TRANSACTION";
export const UPDATE_BANKING_TRANSACTION_SUCCESS =
  "UPDATE_BANKING_TRANSACTION_SUCCESS";
export const DELETE_BANKING_TRANSACTION = "DELETE_BANKING_TRANSACTION";
export const DELETE_BANKING_TRANSACTION_SUCCESS =
  "DELETE_BANKING_TRANSACTION_SUCCESS";

//FILE
export const ADD_FILE = "ADD_FILE";
export const ADD_FILE_SUCCESS = "ADD_FILE_SUCCESS";
export const GET_ALL_FILES = "GET_ALL_FILES";
export const GET_ALL_FILES_SUCCESS = "GET_ALL_FILES_SUCCESS";
export const GET_ALL_MONTHLY_FILES = "GET_ALL_MONTHLY_FILES";
export const GET_ALL_MONTHLY_FILES_SUCCESS = "GET_ALL_MONTHLY_FILES_SUCCESS";
export const GET_FILE = "GET_FILE";
export const GET_FILE_SUCCESS = "GET_FILE_SUCCESS";
export const UPDATE_FILE = "UPDATE_FILE";
export const UPDATE_FILE_SUCCESS = "UPDATE_FILE_SUCCESS";
export const SELECT_ALL_FILES = "SELECT_ALL_FILES";
export const SELECT_SINGLE_FILE = "SELECT_SINGLE_FILE";
export const DELETE_FILES = "DELETE_FILES";
export const DELETE_FILES_SUCCESS = "DELETE_FILES_SUCCESS";
export const DOWNLOAD_FILE_PDF = "DOWNLOAD_FILE_PDF";
export const DOWNLOAD_FILE_PDF_SUCCESS = "DOWNLOAD_FILE_PDF_SUCCESS";
export const DOWNLOAD_CERTIFICATE_OF_WITHHOLDING_TAX = "DOWNLOAD_CERTIFICATE_OF_WITHHOLDING_TAX";
export const DOWNLOAD_CERTIFICATE_OF_WITHHOLDING_TAX_SUCCESS = "DOWNLOAD_CERTIFICATE_OF_WITHHOLDING_TAX_SUCCESS";
export const SEND_MAIL_CERTIFICATE_OF_WITHHOLDING_TAX = "SEND_MAIL_CERTIFICATE_OF_WITHHOLDING_TAX";
export const SEND_MAIL_CERTIFICATE_OF_WITHHOLDING_TAX_SUCCESS = "SEND_MAIL_CERTIFICATE_OF_WITHHOLDING_TAX_SUCCESS";
export const FETCH_WITHHOLDING_TAX = "FETCH_WITHHOLDING_TAX";
export const FETCH_WITHHOLDING_TAX_SUCCESS = "FETCH_WITHHOLDING_TAX_SUCCESS"; 
export const FETCH_LOCAL_EQUIPEMENT_TAX = "FETCH_LOCAL_EQUIPEMENT_TAX";
export const FETCH_LOCAL_EQUIPEMENT_TAX_SUCCESS = "FETCH_LOCAL_EQUIPEMENT_TAX_SUCCESS"; 
export const FETCH_LOCAL_PURCHASE_TAX = "FETCH_LOCAL_PURCHASE_TAX";
export const FETCH_LOCAL_PURCHASE_TAX_SUCCESS = "FETCH_LOCAL_PURCHASE_TAX_SUCCESS"; 
export const FETCH_IMPORTED_EQUIPEMENT_TAX = "FETCH_IMPORTED_EQUIPEMENT_TAX";
export const FETCH_IMPORTED_EQUIPEMENT_TAX_SUCCESS = "FETCH_IMPORTED_EQUIPEMENT_TAX_SUCCESS"; 
export const FETCH_IMPORTED_PURCHASE_TAX = "FETCH_IMPORTED_PURCHASE_TAX";
export const FETCH_IMPORTED_PURCHASE_TAX_SUCCESS = "FETCH_IMPORTED_PURCHASE_TAX_SUCCESS"; 

//bankAccount
export const ADD_BANK_ACCOUNT = "ADD_BANK_ACCOUNT";
export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";
export const GET_ALL_BANK_ACCOUNTS = "GET_ALL_BANK_ACCOUNTS";
export const GET_ALL_BANK_ACCOUNTS_SUCCESS = "GET_ALL_BANK_ACCOUNT_SUCCESS";
export const GET_BANK_ACCOUNT = "GET_BANK_ACCOUNT";
export const GET_BANK_ACCOUNT_SUCCESS = "GET_BANK_ACCOUNT_SUCCESS";
export const UPDATE_BANK_ACCOUNT = "UPDATE_BANK_ACCOUNT";
export const UPDATE_BANK_ACCOUNT_SUCCES = "UPDATE_BANK_ACCOUNT_SUCCESS";
export const SELECT_ALL_BANK_ACCOUNTS = "SELECT_ALL_BANK_ACCOUNTS";
export const SELECT_SINGLE_BANK_ACCOUNT = "SELECT_SINGLE_BANK_ACCOUNT";
export const DELETE_BANK_ACCOUNT = "DELETE_BANK_ACCOUNT";
export const DELETE_BANK_ACCOUNT_SUCCESS = "DELETE_BANK_ACCOUNT_SUCCESS";

//SYSTEM
export const ADD_SYSTEM = "ADD_SYSTEM";
export const ADD_SYSTEM_SUCCESS = "ADD_SYSTEM_SUCCESS";
export const GET_ALL_SYSTEMS = "GET_ALL_SYSTEMS";
export const GET_ALL_SYSTEMS_SUCCESS = "GET_ALL_SYSTEMS_SUCCESS";
export const GET_SYSTEM = "GET_SYSTEM";
export const GET_SYSTEM_SUCCESS = "GET_SYSTEM_SUCCESS";
export const UPDATE_SYSTEM = "UPDATE_SYSTEM";
export const UPDATE_SYSTEM_SUCCESS = "UPDATE_SYSTEM_SUCCESS";
export const SELECT_ALL_SYSTEMS = "SELECT_ALL_SYSTEMS";
export const SELECT_SINGLE_SYSTEM = "SELECT_SINGLE_SYSTEM";
export const DELETE_SYSTEMS = "DELETE_SYSTEMS";
export const DELETE_SYSTEMS_SUCCESS = "DELETE_SYSTEMS_SUCCESS";

export const SORT_DOCTORS_BY_NEWEST = "SORT_DOCTORS_BY_NEWEST";
export const SEARCH_DOCTOR = "SEARCH_DOCTOR";
export const SEARCH_DOCTOR_MASTER = "SEARCH_DOCTOR_MASTER";

//ADD_MODAL
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

//Edit Modal
export const SHOW_EDIT_MODAL = "SHOW_EDIT_MODAL";
export const HIDE_EDIT_MODAL = "HIDE_EDIT_MODAL";

// DOCUMENT_TEMPLATE
export const CREATE_DOCUMENT_TEMPLATE = "CREATE_DOCUMENT_TEMPLATE";
export const CREATE_DOCUMENT_TEMPLATE_SUCCESS =
  "CREATE_DOCUMENT_TEMPLATE_SUCCESS";
export const DELETE_DOCUMENT_TEMPLATE = "DELETE_DOCUMENT_TEMPLATE";
export const DELETE_DOCUMENT_TEMPLATE_SUCCESS =
  "DELETE_DOCUMENT_TEMPLATE_SUCCESS";
export const GET_DOCUMENT_TEMPLATE = "GET_DOCUMENT_TEMPLATE";
export const GET_DOCUMENT_TEMPLATE_SUCCESS = "GET_DOCUMENT_TEMPLATE_SUCCESS";
export const GET_DOCUMENT_TEMPLATE_BY_ID = "GET_DOCUMENT_TEMPLATE_BY_ID";
export const GET_DOCUMENT_TEMPLATE_BY_ID_SUCCESS =
  "GET_DOCUMENT_TEMPLATE_BY_ID_SUCCESS";
export const SEARCH_DOCUMENT_TEMPLATES = "SEARCH_DOCUMENT_TEMPLATES";
export const UPDATE_DOCUMENT_TEMPLATE = "UPDATE_DOCUMENT_TEMPLATE";
export const UPDATE_DOCUMENT_TEMPLATE_SUCCESS =
  "UPDATE_DOCUMENT_TEMPLATE_SUCCESS";

//Reviews
export const GET_ALL_REVIEWS_BY_DOCUMENT_TEMPLATE =
  "GET_ALL_REVIEWS_BY_DOCUMENT_TEMPLATE";
export const GET_ALL_REVIEWS_BY_DOCUMENT_TEMPLATE_SUCCESS =
  "GET_ALL_REVIEWS_BY_DOCUMENT_TEMPLATE_SUCCESS";
export const SUBMIT_REVIEW_BY_DOCUMENT_TEMPLATE =
  "SUBMIT_REVIEW_BY_DOCUMENT_TEMPLATE";
export const SUBMIT_REVIEW_BY_DOCUMENT_TEMPLATE_SUCCESS =
  "SUBMIT_REVIEW_BY_DOCUMENT_TEMPLATE_SUCCESS";
export const GET_RECENT_REVIEWS_BY_DOCUMENT_TEMPLATE =
  "GET_RECENT_BY_DOCUMENT_TEMPLATE";
export const GET_RECENT_REVIEWS_BY_DOCUMENT_TEMPLATE_SUCCESS =
  "GET_RECENT_REVIEWS_BY_DOCUMENT_TEMPLATE_SUCCESS";
export const SEARCH_REVIEWS = "SEARCH_REVIEWS";
export const SEARCH_REVIEW_DOCUMENT_TEMPLATE =
  "SEARCH_REVIEW_DOCUMENT_TEMPLATE";

// produit charts
export const GET_PRODUCT_RELATED_INDICATORS = "GET_PRODUCT_RELATED_INDICATORS";
export const GET_PRODUCT_RELATED_INDICATORS_SUCCESS =
  "GET_PRODUCT_RELATED_INDICATORS_SUCCESS";
export const POST_SATISFACTION_GRAPH = "POST_SATISFACTION_GRAPH";
export const POST_SATISFACTION_GRAPH_SUCCESS =
  "POST_SATISFACTION_GRAPH_SUCCESS";
export const GET_PATHOLOGIE_RELATED_INDICATORS =
  "GET_PATHOLOGIE_RELATED_INDICATORS";
export const GET_PATHOLOGIE_RELATED_INDICATORS_SUCCESS =
  "GET_PATHOLOGIE_RELATED_INDICATORS_SUCCESS";
export const GET_COMBINE_RELATED_INDICATORS = "GET_COMBINE_RELATED_INDICATORS";
export const GET_COMBINE_RELATED_INDICATORS_SUCCESS =
  "GET_COMBINE_RELATED_INDICATORS_SUCCESS";
export const GET_DOCTOR_RELATED_INDICATORS = "GET_DOCTOR_RELATED_INDICATORS";
export const GET_DOCTOR_RELATED_INDICATORS_SUCCESS =
  "GET_DOCTOR_RELATED_INDICATORS_SUCCESS";

// COVID charts
export const GET_COVID_TRETEMENTS = "GET_COVID_TRETEMENTS";
export const GET_COVID_TRETEMENTS_SUCCESS = "GET_COVID_TRETEMENTS_SUCCESS";
export const POST_SATURATION_O2_GRAPH = "POST_SATURATION_O2_GRAPH";
export const POST_SATURATION_O2_GRAPH_SUCCESS =
  "POST_SATURATION_O2_GRAPH_SUCCESS";
export const POST_SATISFACTION_GRAPH_MY_DOCUMENT_TEMPLATES =
  "POST_SATISFACTION_GRAPH_MY_DOCUMENT_TEMPLATES";
export const POST_SATISFACTION_GRAPH_MY_DOCUMENT_TEMPLATES_SUCCESS =
  "POST_SATISFACTION_GRAPH_MY_DOCUMENT_TEMPLATES_SUCCESS";
export const POST_SATISFACTION_GRAPH_ALL_MY_DOCUMENT_TEMPLATES =
  "POST_SATISFACTION_GRAPH_ALL_MY_DOCUMENT_TEMPLATES";
export const POST_SATISFACTION_GRAPH_ALL_MY_DOCUMENT_TEMPLATES_SUCCESS =
  "POST_SATISFACTION_GRAPH_ALL_MY_DOCUMENT_TEMPLATES_SUCCESS";

export const GET_NOTES_BY_DOCTOR = "GET_NOTES_BY_DOCTOR";
export const GET_NOTES_BY_DOCTOR_SUCCESS = "GET_NOTES_BY_DOCTOR_SUCCESS";

export const GET_STAT_BY_MASTER = "GET_STAT_BY_MASTER";
export const GET_STAT_BY_MASTER_SUCCESS = "GET_STAT_BY_MASTER_SUCCESS";

export const GET_TOP_DOCTORS = "GET_TOP_DOCTORS";
export const GET_TOP_DOCTORS_SUCCESS = "GET_TOP_DOCTORS_SUCCESS";

export const GET_MASTER_STATE_GRAPH = "GET_MASTER_STATE_GRAPH";
export const GET_MASTER_STATE_GRAPH_SUCCESS = "GET_MASTER_STATE_GRAPH_SUCCESS";

export const DISABLE_NOTIFICATIONS = "DISABLE_NOTIFICATIONS";
export const DISABLE_NOTIFICATIONS_SUCCESS = "DISABLE_NOTIFICATIONS_SUCCESS";

export const GET_FARMERS_PRODUCTS_BY_DOCTOR = "GET_FARMERS_PRODUCTS_BY_DOCTOR";
export const GET_FARMERS_PRODUCTS_BY_DOCTOR_SUCCESS =
  "GET_FARMERS_PRODUCTS_BY_DOCTOR_SUCCESS";

// can be deleted
export const GET_ALL_FARMERS_PRODUCTS_BY_DOCUMENT_TEMPLATE_SUCCESS =
  "GET_ALL_FARMERS_PRODUCTS_BY_DOCUMENT_TEMPLATE_SUCCESS";
export const GET_LATEST_FARMERS_PRODUCTS_BY_DOCUMENT_TEMPLATE =
  "GET_LATEST_FARMERS_PRODUCTS_BY_DOCUMENT_TEMPLATE";
export const GET_LATEST_FARMERS_PRODUCTS_BY_DOCUMENT_TEMPLATE_SUCCESS =
  "GET_LATEST_FARMERS_PRODUCTS_BY_DOCUMENT_TEMPLATE_SUCCESS";
export const GET_SINGLE_FARMERS_PRODUCTS_BY_MASTER =
  "GET_SINGLE_FARMERS_PRODUCTS_BY_MASTER";
export const GET_SINGLE_FARMERS_PRODUCTS_BY_MASTER_SUCCESS =
  "GET_SINGLE_FARMERS_PRODUCTS_BY_MASTER_SUCCESS";
export const ORDER_FARMERS_PRODUCTS_BY_LATEST =
  "ORDER_FARMERS_PRODUCTS_BY_LATEST";
export const ORDER_FARMERS_PRODUCTS_BY_LATEST_SUCCESS =
  "ORDER_FARMERS_PRODUCTS_BY_LATEST_SUCCESS";
export const ORDER_FARMERS_PRODUCTS_BY_LATEST_UPDATE =
  "ORDER_FARMERS_PRODUCTS_BY_LATEST_UPDATE";
export const ORDER_DOCUMENT_TEMPLATE_FARMERS_PRODUCTS_BY_LATEST =
  "ORDER_DOCUMENT_TEMPLATE_FARMERS_PRODUCTS_BY_LATEST";
export const ORDER_DOCUMENT_TEMPLATE_FARMERS_PRODUCTS_BY_LATEST_UPDATE =
  "ORDER_DOCUMENT_TEMPLATE_FARMERS_PRODUCTS_BY_LATEST_UPDATE";
export const RESET_DOCUMENT_TEMPLATE_FARMERS_PRODUCTS_LIST =
  "RESET_DOCUMENT_TEMPLATE_FARMERS_PRODUCTS_LIST";
export const RESET__FARMERS_PRODUCTS_LIST = "RESET__FARMERS_PRODUCTS_LIST";
export const SEARCH_FARMERS_PRODUCTS_MASTER = "SEARCH_FARMERS_PRODUCTS_MASTER";
export const SEARCH_FARMERS_PRODUCTS_DOCUMENT_TEMPLATE =
  "SEARCH_FARMERS_PRODUCTS_DOCUMENT_TEMPLATE";
export const GET_ALL_INVOICES_BY_DOCUMENT_TEMPLATE_SUCCESS =
  "GET_ALL_INVOICES_BY_DOCUMENT_TEMPLATE_SUCCESS";
export const GET_LATEST_INVOICES_BY_DOCUMENT_TEMPLATE =
  "GET_LATEST_INVOICES_BY_DOCUMENT_TEMPLATE";
export const GET_LATEST_INVOICES_BY_DOCUMENT_TEMPLATE_SUCCESS =
  "GET_LATEST_INVOICES_BY_DOCUMENT_TEMPLATE_SUCCESS";
export const GET_SINGLE_INVOICES_BY_MASTER = "GET_SINGLE_INVOICES_BY_MASTER";
export const GET_SINGLE_INVOICES_BY_MASTER_SUCCESS =
  "GET_SINGLE_INVOICES_BY_MASTER_SUCCESS";
export const ORDER_INVOICES_BY_LATEST = "ORDER_INVOICES_BY_LATEST";
export const ORDER_INVOICES_BY_LATEST_UPDATE =
  "ORDER_INVOICES_BY_LATEST_UPDATE";
export const ORDER_DOCUMENT_TEMPLATE_INVOICES_BY_LATEST =
  "ORDER_DOCUMENT_TEMPLATE_INVOICES_BY_LATEST";
export const ORDER_DOCUMENT_TEMPLATE_INVOICES_BY_LATEST_UPDATE =
  "ORDER_DOCUMENT_TEMPLATE_INVOICES_BY_LATEST_UPDATE";
export const RESET_DOCUMENT_TEMPLATE_INVOICES_LIST =
  "RESET_DOCUMENT_TEMPLATE_INVOICES_LIST";
export const RESET__INVOICES_LIST = "RESET__INVOICES_LIST";
export const SEARCH_INVOICES_MASTER = "SEARCH_INVOICES_MASTER";
export const SEARCH_INVOICES_DOCUMENT_TEMPLATE =
  "SEARCH_INVOICES_DOCUMENT_TEMPLATE";
export const GET_ALL_QUOTATIONS_BY_DOCUMENT_TEMPLATE_SUCCESS =
  "GET_ALL_QUOTATIONS_BY_DOCUMENT_TEMPLATE_SUCCESS";
export const GET_LATEST_QUOTATIONS_BY_DOCUMENT_TEMPLATE =
  "GET_LATEST_QUOTATIONS_BY_DOCUMENT_TEMPLATE";
export const GET_LATEST_QUOTATIONS_BY_DOCUMENT_TEMPLATE_SUCCESS =
  "GET_LATEST_QUOTATIONS_BY_DOCUMENT_TEMPLATE_SUCCESS";
export const GET_SINGLE_QUOTATIONS_BY_MASTER =
  "GET_SINGLE_QUOTATIONS_BY_MASTER";
export const GET_SINGLE_QUOTATIONS_BY_MASTER_SUCCESS =
  "GET_SINGLE_QUOTATIONS_BY_MASTER_SUCCESS";
export const ORDER_QUOTATIONS_BY_LATEST = "ORDER_QUOTATIONS_BY_LATEST";
export const ORDER_QUOTATIONS_BY_LATEST_UPDATE =
  "ORDER_QUOTATIONS_BY_LATEST_UPDATE";
export const ORDER_DOCUMENT_TEMPLATE_QUOTATIONS_BY_LATEST =
  "ORDER_DOCUMENT_TEMPLATE_QUOTATIONS_BY_LATEST";
export const ORDER_DOCUMENT_TEMPLATE_QUOTATIONS_BY_LATEST_UPDATE =
  "ORDER_DOCUMENT_TEMPLATE_QUOTATIONS_BY_LATEST_UPDATE";
export const RESET_DOCUMENT_TEMPLATE_QUOTATIONS_LIST =
  "RESET_DOCUMENT_TEMPLATE_QUOTATIONS_LIST";
export const RESET__QUOTATIONS_LIST = "RESET__QUOTATIONS_LIST";
export const SEARCH_QUOTATIONS_MASTER = "SEARCH_QUOTATIONS_MASTER";
export const SEARCH_QUOTATIONS_DOCUMENT_TEMPLATE =
  "SEARCH_QUOTATIONS_DOCUMENT_TEMPLATE";

export const TOP_CLIENTS = "TOP_CLIENTS";
export const TOP_CLIENTS_SUCCESS = "TOP_CLIENTS_SUCCESS";
export const TOP_PRODUCTS = "TOP_PRODUCTS";
export const TOP_PRODUCTS_SUCCESS = "TOP_PRODUCTS_SUCCESS";
export const YEARLY_SALES = "YEARLY_SALES";
export const YEARLY_SALES_SUCCESS = "YEARLY_SALES_SUCCESS";
export const INVOICES_PAYMENT_STATUSES = "INVOICES_PAYMENT_STATUSES";
export const INVOICES_PAYMENT_STATUSES_SUCCESS = "INVOICES_PAYMENT_STATUSES_SUCCESS";

//form state
export const SAVE_FORM_STATE = "SAVE_FORM_STATE"; 
export const UPDATE_FORM_STATE_SUCCESS = "UPDATE_FORM_STATE_SUCCESS"; 

export const SIMULATE_PREVIEW = "SIMULATE_PREVIEW"; 
export const SIMULATE_PREVIEW_SUCCESS = "SIMULATE_PREVIEW_SUCCESS"; 