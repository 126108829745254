import {
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCTS,
  DELETE_PRODUCTS_SUCCESS,
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  SEARCH_DOCTOR,
  SHOW_MESSAGE,
  SEARCH_DOCTOR_MASTER,
} from "../../constants/ActionTypes";

export const addProduct = (payload) => {
  return {
    type: ADD_PRODUCT,
    payload,
  };
};

export const addProductSuccess = (payload) => {
  return {
    type: ADD_PRODUCT_SUCCESS,
    payload,
  };
};

export const updateProduct = (payload, redirect) => { 
  return {
    type: UPDATE_PRODUCT,
    payload,
    redirect
  };
};

export const updateProductSuccess = (payload) => {
  return {
    type: UPDATE_PRODUCT_SUCCESS,
    payload,
  };
};

export const getProduct = (payload) => {
  return {
    type: GET_PRODUCT,
    payload,
  };
};

export const getProductSuccess = (product) => {
  return {
    type: GET_PRODUCT_SUCCESS,
    payload: product,
  };
};

export const deleteProduct = (id, oldList) => {
  return {
    type: DELETE_PRODUCTS,
    payload: { id, oldList },
  };
};

export const deleteProductSuccess = (payload) => {
  return {
    type: DELETE_PRODUCTS_SUCCESS,
    payload,
  };
};

export const getListOfProducts = (page, take) => {
  return {
    type: GET_ALL_PRODUCTS,
    page,
    take
  };
};

export const getListOfProductsSuccess = (product) => {
  return {
    type: GET_ALL_PRODUCTS_SUCCESS,
    payload: product,
  };
};

export const showProductMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const searchProduct = (value, list, filterOption = "name") => {
  try {
    let selected;
    if (value !== "") {
      selected = list.filter(
        (item, index) =>
          item[filterOption]
            .toString()
            .toString()
            .toLowerCase()
            .search(value.toLowerCase()) !== -1
      );
    } else {
      selected = list;
    }
    return {
      type: SEARCH_DOCTOR,
      payload: selected ? selected : [],
    };
  } catch (error) {
    console.error(error);
  }
};
export const _m = (list, selectedOption) => {
  let selected = [];
  if (Object.values(selectedOption)[0] === 1) {
    selected = list;
  } else {
    let dateNow = new Date();
    dateNow.setDate(dateNow.getDate() - 0); // - 0 ==> today
    selected = list.filter(
      (item) =>
        item[Object.keys(selectedOption)[0]].split("T")[0] ===
        dateNow.toISOString().split("T")[0]
    );
  }
  return {
    type: SEARCH_DOCTOR_MASTER,
    payload: selected ? selected : [],
  };
};
