import {
  SIMULATE_PREVIEW,
  SIMULATE_PREVIEW_SUCCESS
} from "../../constants/ActionTypes";

const simulatePreviewInitState = {
  preview: {},
  loader: true,
};

const SimulatePreviewReducers = (state = simulatePreviewInitState, action) => {

  let { type, payload } = action;
  switch (type) {
    case SIMULATE_PREVIEW:
      return { ...state, loader: true };
    case SIMULATE_PREVIEW_SUCCESS:
      return { ...state, preview: payload, loader: false };
    default:
      return state;
  }
};

export default SimulatePreviewReducers;