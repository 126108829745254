import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Select, Row, Col, notification } from "antd";
import { Link, useHistory } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { hideMessage, showAuthLoader, userSignUp } from "appRedux/actions/Auth";
import backgroundImage from 'assets/images/loginPageBackground.png';
import { ReactComponent as Logo } from "../assets/images/logo.svg";

import IntlMessages from "util/IntlMessages";
import { message } from "antd/lib/index";
import CircularProgress from "components/CircularProgress/index";
import { COUNTRY_CODE, PROFESSIONS } from "../constants/variables";
import "./styles.css";

const FormItem = Form.Item;

const SignUp = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );

  const [countryCode, setCountryCode] = useState('TN')

  const [checks, setChecks] = useState({
    upperLetterCheck: false,
    lowerLetterCheck: false,
    numberCheck: false,
    lengthCheck: false,
  });

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push("/");
    }

    if (window) {
      setCountryCode(window.location.host.includes('fr') ? 'FR' : 'TN')
    }

  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  const otherPwdMessage = (
    <div>
      <p>
        <span className="text-colors">le mot de passe doit contenir :</span>
        <br />
        <ul>
          <li
            className={`text-color-${checks.lengthCheck ? "valide" : "invalide"
              }`}
          >
            Minimum de 8 caractères
          </li>
          <li
            className={`text-color-${checks.upperLetterCheck ? "valide" : "invalide"
              }`}
          >
            1 caractère alphabétique majuscule
          </li>
          <li
            className={`text-color-${checks.lowerLetterCheck ? "valide" : "invalide"
              }`}
          >
            1 caractère alphabétique minuscule
          </li>
          <li
            className={`text-color-${checks.numberCheck ? "valide" : "invalide"
              }`}
          >
            1 chiffre
          </li>
        </ul>
      </p>
    </div>
  );

  const onChangePwd = (event) => {
    const { value } = event.target;

    const upperLetterCheck = /(?=.*[A-Z])/.test(value);
    const lowerLetterCheck = /(?=.*[a-z])/.test(value);
    const numberCheck = /(?=.*[0-9])/.test(value);
    const lengthCheck = /(?=.{8,})/.test(value);
    setChecks({
      upperLetterCheck,
      lowerLetterCheck,
      numberCheck,
      lengthCheck,
    });
  };

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    dispatch(
      userSignUp({
        ...values,
        countryCode,
        /*
        phone:
          countryCode === "TN"
            ? ["+216", values.phone].join("")
            : ["+33", values.phone].join(""),
            */
      })
    );
  };

  return (
    <div className="bg-image-login" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="login-form">
        <div className="logo">
          <Logo />
        </div>
        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem
            name="givenName"
            rules={
              [{
                required: true,
                message: "Veuillez entrer votre prénom"
              }]}
          >
            <Input placeholder="Prénom" />
          </FormItem>
          <FormItem
            name="familyName"
            rules={
              [{
                required: true,
                message: "Veuillez entrer votre nom"
              }]
            }
          >
            <Input placeholder="Nom" />
          </FormItem>
          <FormItem
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Veuillez entrer une adresse e-mail valide",
              },
            ]}
          >
            <Input placeholder="Email" />
          </FormItem>
          <FormItem
            name="password"
            rules={[
              {
                required: true,
                message: otherPwdMessage,
              },
              {
                pattern: new RegExp(
                  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
                ),
                message: otherPwdMessage,
              },
            ]}
          >
            <Input.Password
              onChange={onChangePwd}
              //type="password"
              placeholder="Mot de passe"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </FormItem>
          <FormItem
            name="Confirm"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Veuillez confirmer votre mot de passe",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Les deux mots de passe correspondent pas"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              //type="password"
              placeholder="Confirmez votre mot de passe"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </FormItem>
          <FormItem>
            <Button type="primary" className="add-btn" htmlType="submit">
              <IntlMessages id="app.userAuth.signUp" />
            </Button>
            <span>
              <IntlMessages id="app.userAuth.or" />
            </span>{" "}
            <Link to="/signin">
              <IntlMessages id="app.userAuth.signIn" />
            </Link>
          </FormItem>
        </Form>
      </div>
      {loader && (
        <div className="loader-position-sign-up">
          <CircularProgress />
        </div>
      )}
      {showMessage &&
        notification.error({
          message: alertMessage,
          duration: 20
        })}
    </div>
  );
};

export default SignUp;
