import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import UpdatePassword from "./UpdatePassword";
import { ACCOUNTANT_ROUTES, CLIENT_ROUTES } from "./constants";
import ClearCacheButton from './../components/ClearCache';
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "./styles.less";

const App = ({ match }) => {
  const [routes, setRoutes] = useState([]);
  const { authUser = {} } = useSelector(({ auth }) => auth);
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const { role, countryCode, email, userId } = jwtDecode(localStorage.getItem("token"));
      const { isIdentityVerified } = authUser
      role === "ACCOUNTANT"
        ? setRoutes(ACCOUNTANT_ROUTES)
        : setRoutes(CLIENT_ROUTES);
      if (history && history.location) {
        const pathname = history.location.pathname
        if (!isIdentityVerified && pathname.includes('create') || pathname.includes('update')) {
          // history.push('/identified-user')
        }
      }

      localStorage.setItem('countryCode', countryCode)
      localStorage.setItem('role', role)
      localStorage.setItem('email', email)
      localStorage.setItem('userId', userId)
      localStorage.setItem('toFixed', countryCode === "TN" ? 3 : 2)

    }

  }, [JSON.stringify(localStorage.getItem("token") || "")]);

  return (
    <div className="gx-main-content-wrapper custom-content">
      <ClearCacheButton />
      <UpdatePassword />
      <Switch>
        {routes.map(({ url, path }) => (
          <Route
            key={url}
            exact
            path={`${match.url}${url}`}
            component={asyncComponent(() => import(`${path}`))}
          />
        ))}
        <Route component={asyncComponent(() => import("./404"))} />
      </Switch>
    </div>
  );
};

export default App;
