import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { post } from "../../axios/master";
import { SIMULATE_PREVIEW } from "../../constants/ActionTypes";
import { simulatePreviewSuccess } from "../actions/SimulatePreview";
import { fetchError } from "../actions/Common";
import { notification } from "antd";

const SIMULATE = {
  QUOTATION: 'quotations/simulate-quotation',
  INVOICE: 'invoices/simulate-invoice',
  DELIVERY_NOTE: 'invoices/simulate-invoice',
  FEE_NOTE: 'invoices/simulate-invoice',
  RELEASE_NOTE: 'invoices/simulate-invoice',
}

function* simulatePreviewApi({ payload, entityType }) {
  try {   
    const response = yield call(post, SIMULATE[entityType], payload);
    if (response) {
      yield put(simulatePreviewSuccess(response));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

export function* simulatePreviewSaga() {
  yield takeEvery(SIMULATE_PREVIEW, simulatePreviewApi);
}

export default function* rootSaga() {
  yield all([
    fork(simulatePreviewSaga)
  ]);
}