import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import ProductReducers from "./Products";
import TaxClassReducers from "./TaxClass";
import EnterpriseReducers from "./Enterprise";
import FileReducers from "./File";
import InvoiceReducers from "./Invoice";
import QuotationReducers from "./Quotation";
import MonthlyReportsReducers from "./MonthlyReport";
import documentTemplates from "./DocumentTemplate";
import BankingTransactionReducers from "./BankingTransaction";
import BankAccountReducers from "./BankAccount";
import FormStateReducers from "./FormState";
import ProfileReducer from "./Profile";
import UserReducer from "./User";
import UnityReducers from "./Unity";
import SimulatePreviewReducers from "./SimulatePreview";
// unused
import GraphsReducer from "./GraphsReducer";
import MasterStatReducer from "./Stats";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    products: ProductReducers,
    files: FileReducers,
    profile: ProfileReducer,
    user: UserReducer,
    enterprises: EnterpriseReducers,
    taxClasses: TaxClassReducers,
    invoices: InvoiceReducers,
    quotations: QuotationReducers,
    monthlyReports: MonthlyReportsReducers,
    bankAccounts: BankAccountReducers,
    formState: FormStateReducers,
    documentTemplates,
    transactions: BankingTransactionReducers,
    unities: UnityReducers,
    simulatePreviews: SimulatePreviewReducers,
    // unused
    graph: GraphsReducer,
    stats: MasterStatReducer,
  });

export default createRootReducer;
