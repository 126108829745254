
export const CLIENT_ROUTES = [
  {
    url: "",
    path: "./Master/Home",
  },
  {
    url: "identified-user",
    path: "./Master/Setting/IdentifiedUser"
  },
  {
    url: "tax-class",
    path: "./Master/TaxClass",
  },
  {
    url: "document-template",
    path: "./Master/DocumentTemplate",
  },
  {
    url: "files",
    path: "./Master/File",
  },
  {
    url: "file/create",
    path: "./Master/File/Add",
  },
  {
    url: "file/update/:id",
    path: "./Master/File/Edit",
  },
  {
    url: "file/:id",
    path: "./Master/File/Profile",
  },
  {
    url: "document-template/:id",
    path: "./Master/DocumentTemplate/Profile",
  },
  {
    url: "clients",
    path: "./Master/Client",
  },
  {
    url: "client",
    path: "./Master/Client/Profile",
  },
  {
    url: "client/create",
    path: "./Master/Client/Add",
  },
  {
    url: "client/update/:id",
    path: "./Master/Client/Edit",
  },
  {
    url: "client/:id",
    path: "./Master/Client/Profile",
  },
  {
    url: "bank-account",
    path: "./Master/BankAccount",
  },
  {
    url: "bank-account/:id",
    path: "./Master/BankAccount/Profile",
  },
  {
    url: "products",
    path: "./Master/Product",
  },
  {
    url: "product/create",
    path: "./Master/Product/Add",
  },
  {
    url: "product/update/:id",
    path: "./Master/Product/Edit",
  },
  {
    url: "product/:id",
    path: "./Master/Product/Profile",
  },
  {
    url: "invoices",
    path: "./Master/Invoice",
  },
  {
    url: "invoice/create",
    path: "./Master/Invoice/Add",
  },
  {
    url: "invoice-from-delivery-note/create",
    path: "./Master/Invoice/DeliveryNoteToInvoice"
  },
  {
    url: "invoice/:id",
    path: "./Master/Invoice/Profile",
  },
  {
    url: "invoice/duplicate/:id",
    path: "./Master/Invoice/Duplicate",
  },
  {
    url: "delivery-notes",
    path: "./Master/DeliveryNote"
  },
  {
    url: "delivery-note/create",
    path: "./Master/DeliveryNote/Add"
  },
  {
    url: "invoice-of-delivery-note/create",
    path: "./Master/DeliveryNote/AddToInvoice"
  },
  {
    url: "delivery-note/update/:id",
    path: "./Master/DeliveryNote/Edit",
  },
  {
    url: "delivery-note/:id",
    path: "./Master/DeliveryNote/Profile",
  },
  {
    url: "release-notes",
    path: "./Master/ReleaseNote",
  },
  {
    url: "release-note/create",
    path: "./Master/ReleaseNote/Add"
  },
  {
    url: "release-note/update/:id",
    path: "./Master/ReleaseNote/Edit",
  },
  {
    url: "release-note/:id",
    path: "./Master/ReleaseNote/Profile",
  },
  {
    url: "fee-notes",
    path: "./Master/FeeNote"
  },
  {
    url: "fee-note/create",
    path: "./Master/FeeNote/Add"
  },
  {
    url: "fee-note/update/:id",
    path: "./Master/FeeNote/Edit",
  },
  {
    url: "fee-note/:id",
    path: "./Master/FeeNote/Profile",
  },
  {
    url: "invoice-from-delivery-note/create",
    path: "./Master/FeeNote/DeliveryNoteToInvoice"
  },
  {
    url: "fee-note/duplicate/:id",
    path: "./Master/FeeNote/Duplicate",
  },
  {
    url: "quotations",
    path: "./Master/Quotation",
  },
  {
    url: "quotation/create",
    path: "./Master/Quotation/Add",
  },
  {
    url: "quotation/update/:id",
    path: "./Master/Quotation/Edit",
  },
  {
    url: "quotation/duplicate/:id",
    path: "./Master/Quotation/Duplicate",
  },
  {
    url: "banking-transaction/create",
    path: "./Master/BankingTransaction/Add",
  },
  {
    url: "banking-transaction/update/:id",
    path: "./Master/BankingTransaction/Edit",
  },
  {
    url: "banking-transactions",
    path: "./Master/BankingTransaction",
  },
  {
    url: "quotation/:id",
    path: "./Master/Quotation/Profile",
  },
  {
    url: "m/statistics",
    path: "./Master/Statistics",
  },
  {
    url: "settings",
    path: "./Master/Setting",
  },
  {
    url:"rapport-comptable",
    path: "./Master/RapportComptable",
  },
  {
    url: "rapport-comptable/file/:id",
    path: "./Master/RapportComptable/File/Profile",
  },
  {
    url: "rapport-comptable/invoice/:id",
    path: "./Master/RapportComptable/Invoice/Profile",
  }
];

export const ACCOUNTANT_ROUTES = [
  {
    url: "",
    path: "./Accountant/Client",
  },
  {
    url: "monthly-performance-report",
    path: "./Accountant/Report",
  },
  {
    url: "create/monthly-performance-report/client/:id",
    path: "./Accountant/Report/Add"
  },
  {
    url: "update/monthly-performance-report/:id",
    path: "./Accountant/Report/Edit"
  },
  {
    url:"rapport-comptable/client/:id",
    path: "./Master/RapportComptable",
  },
  {
    url: "rapport-comptable/client/:id/file/:id",
    path: "./Master/RapportComptable/File/Profile",
  },
  {
    url: "rapport-comptable/client/:id/invoice/:id"  ,
    path: "./Master/RapportComptable/Invoice/Profile",
  },
  {
    url: "create-account/:clientId/:accountantEmail/:countryCode",
    path: "./Accountant/SignUp",
  },
];
