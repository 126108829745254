import {
  SIMULATE_PREVIEW,
  SIMULATE_PREVIEW_SUCCESS
} from "../../constants/ActionTypes";

export const simulatePreview = (payload, entityType) => {
  return {
    type: SIMULATE_PREVIEW,
    payload,
    entityType
  };
};

export const simulatePreviewSuccess = (payload) => {
  return {
    type: SIMULATE_PREVIEW_SUCCESS,
    payload
  };
};
