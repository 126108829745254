import {
  ADD_ENTERPRISE,
  ADD_ENTERPRISE_SUCCESS,
  GET_ENTERPRISE,
  GET_ENTERPRISE_SUCCESS,
  UPDATE_ENTERPRISE,
  UPDATE_ENTERPRISE_SUCCESS,
  DELETE_ENTERPRISES,
  DELETE_ENTERPRISES_SUCCESS,
  SEARCH_DOCTOR_MASTER,
  GET_ALL_ENTERPRISES_SUCCESS,
  GET_ALL_PROVIDER_SUCCESS,
  SEARCH_DOCTOR,
  SELECT_ALL_ENTERPRISES,
  SELECT_SINGLE_ENTERPRISE,
  SORT_DOCTORS_BY_NEWEST,
  SHOW_MODAL,
  HIDE_MODAL,
  HIDE_EDIT_MODAL,
  SHOW_EDIT_MODAL,
  GET_ALL_CUSTOMERS_SUCCESS
} from "../../constants/ActionTypes";

const enterprisesInitState = {
  addModal: false,
  updateModal: false,
  enterprise: {},
  base: [],
  editable: [],
  loader: true,
  meta: {},
};

const EnterpriseReducers = (state = enterprisesInitState, action) => {
  let { type, payload } = action;
  switch (type) {
    case ADD_ENTERPRISE:
      return state;
    case GET_ENTERPRISE:
    case UPDATE_ENTERPRISE:
      return { ...state, };
    case GET_ENTERPRISE_SUCCESS:
    case UPDATE_ENTERPRISE_SUCCESS:
    case ADD_ENTERPRISE_SUCCESS:
      return { ...state, base: [action.payload, ...state.base], editable: [action.payload, ...state.editable], enterprise: action.payload, loader: false };
    case GET_ALL_ENTERPRISES_SUCCESS:
      return {
        ...state,
        base: payload.data,
        editable: payload.data,
        loader: false,
        meta: payload.meta,
      };
    case GET_ALL_CUSTOMERS_SUCCESS:
    case GET_ALL_PROVIDER_SUCCESS:
      return { ...state, base: payload, loader: false };
    case DELETE_ENTERPRISES:
      return {
        ...state,
        loader: true,
      };
    case DELETE_ENTERPRISES_SUCCESS:
      return {
        ...state,
        loader: false,
        base: action.payload
      };
    case SELECT_ALL_ENTERPRISES:
      let newState = state.editable.map((elm) => {
        let newElm = { ...elm, selected: payload };
        return newElm;
      });
      return {
        ...state,
        editable: newState,
      };
    case SELECT_SINGLE_ENTERPRISE:
      let newValue = state.editable.map((elm) => {
        if (elm.id === payload.id) {
          let newElm = { ...elm, selected: payload.checked };
          return newElm;
        } else {
          return elm;
        }
      });
      return { ...state, editable: newValue };
    case SORT_DOCTORS_BY_NEWEST:
      let result = state.base.sort((x, y) => {
        return new Date(y.createdAt) - new Date(x.createdAt);
      });
      return { ...state, editable: result };
    case SEARCH_DOCTOR:
      return { ...state, editable: payload };
    case SEARCH_DOCTOR_MASTER:
      return { ...state, editable: payload };
    case SHOW_MODAL:
      return { ...state, addModal: true };
    case HIDE_MODAL:
      return { ...state, addModal: false };
    case SHOW_EDIT_MODAL:
      return { ...state, updateModal: true, enterprise: action.payload };
    case HIDE_EDIT_MODAL:
      return { ...state, updateModal: false, enterprise: {} };
    default:
      return state;
  }
};

export default EnterpriseReducers;
