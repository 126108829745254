import React, { useEffect } from 'react';

function ClearCacheButton() {
  function clearCacheAndRefresh() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }
    window.location.reload();
  }

  useEffect(() => {
    const storedVersion = localStorage.getItem('deployVersion');
const currentVersion = 39

    if (process.env.NODE_ENV !== 'development' && (!storedVersion || storedVersion != currentVersion)) {
      clearCacheAndRefresh();
      localStorage.setItem('deployVersion', currentVersion);
    }
  }, []);

  return <></>;
}

export default ClearCacheButton;
