import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { post, get, update, remove } from "../../axios/master";
import {
  ADD_PRODUCT,
  DELETE_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_PRODUCT,
  UPDATE_PRODUCT,
} from "../../constants/ActionTypes";
import {
  getListOfProductsSuccess,
  getProductSuccess,
  showProductMessage,
  deleteProductSuccess,
  addProductSuccess,
} from "../actions/Products";
import { fetchError, hideDocModal } from "../actions/Common";
import { message, notification } from "antd";

const baseUrl = "products";

function* addNewProduct({ payload }) {
  try {
    const addProduct = yield call(post, baseUrl, payload);
    if (addProduct) {
      yield put(hideDocModal());
      yield put(addProductSuccess(addProduct))
      notification.success({
        message: "Produit ajouté avec succès",
        duration: 20
      });
      let response = yield call(get, `${baseUrl}`);
      if (response) {
        yield put(getListOfProductsSuccess(response));
      }
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* getDocs({ page, take }) {
  let response = yield call(get, page && take ? `${baseUrl}?page=${page}&take=${take}` : `${baseUrl}`);
  if (response) {
    yield put(getListOfProductsSuccess(response));
  } else {
    yield put(showProductMessage(response.message));
  }
}

function* getSingleDoc({ payload }) {
  try {
    let singleDoc = yield call(get, `${baseUrl}/${payload}`);
    if (singleDoc) {
      yield put(getProductSuccess(singleDoc));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}
function* updateDoc({ payload, redirect }) {
  let { data, id } = payload;
  try {
    let response = yield call(update, `${baseUrl}/${id}`, data);

    redirect()
    if (response) {
      yield put(showProductMessage("Product Modifier avec succées "));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}
function* deleteProduct({ payload: { id, oldList } }) {
  try {
    const response = yield call(remove, `${baseUrl}/${id}`);
    const newList = oldList.filter((item) => item.id !== id);
    yield put(deleteProductSuccess(newList));
    if (response) {
      yield put(showProductMessage("Product Supprimer avec succées "));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}
export function* addDoc() {
  yield takeEvery(ADD_PRODUCT, addNewProduct);
}

function* updateSingleDoc() {
  yield takeEvery(UPDATE_PRODUCT, updateDoc);
}
export function* getEveryProduct() {
  yield takeEvery(GET_ALL_PRODUCTS, getDocs);
}
export function* getProduct() {
  yield takeEvery(GET_PRODUCT, getSingleDoc);
}
export function* deleteDocs() {
  yield takeEvery(DELETE_PRODUCTS, deleteProduct);
}
export default function* rootSaga() {
  yield all([
    fork(addDoc),
    fork(getEveryProduct),
    fork(getProduct),
    fork(updateSingleDoc),
    fork(deleteDocs),
  ]);
}
