import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { post, get, update, remove } from "../../axios/master";
import {
  ADD_ENTERPRISE,
  DELETE_ENTERPRISES,
  GET_ALL_ENTERPRISES,
  GET_ENTERPRISE,
  UPDATE_ENTERPRISE,
  GET_ALL_CUSTOMERS,
  GET_ALL_PROVIDER
} from "../../constants/ActionTypes";
import {
  getListOfEnterprisesSuccess,
  getEnterpriseSuccess,
  showEnterpriseMessage,
  deleteEnterpriseSuccess,
  getListOfAllCustomersSuccess,
  getListOfAllProvidersSuccess,
  addEnterpriseSuccess
} from "../actions/Enterprise";
import { fetchError, hideDocModal } from "../actions/Common";
import { message, notification } from "antd";

const baseUrl = "enterprises";

function* addNewEnterprise({ payload, redirect, path }) {
  try {
    const addEnterprise = yield call(post, baseUrl, payload);
    if (addEnterprise) {
      yield put(addEnterpriseSuccess(addEnterprise))
      // yield put(showEnterpriseMessage("Circuit Ajouter avec succées "));
      yield put(hideDocModal());
      notification.success({
        message: "Client ajouté avec succès",
      duration: 20
    });
      if (redirect) {
        redirect(path)
      }
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
    notification.error({
      message: "L\'ajout du client a échoué",
      duration: 20
    });
  }
}

function* getEnterprises({ filter, page, take }) {
  let response = yield call(get, page && take ? `${baseUrl}?page=${page}&take=${take}` : `${baseUrl}`);
  if (response) {
    yield put(getListOfEnterprisesSuccess(response));
  } else {
    yield put(showEnterpriseMessage(response.message));
  }
}
function* getAllCustomers({ filter }) {
  let response = yield call(get, `${baseUrl}/allClient`);
  if (response) {
    yield put(getListOfAllCustomersSuccess(response));
  } else {
    yield put(showEnterpriseMessage(response.message));
  }
}
function* getAllProviders() {
  let response = yield call(get, `${baseUrl}/allProvider`);
  if (response) {
    yield put(getListOfAllProvidersSuccess(response));
  } else {
    yield put(showEnterpriseMessage(response.message));
  }
}
function* getSingleEnterprise({ payload }) {
  try {
    let singleEnterprise = yield call(get, `${baseUrl}/${payload}`);
    if (singleEnterprise) {
      yield put(getEnterpriseSuccess(singleEnterprise));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}
function* updateEnterprise({ payload }) {
  let { data, id } = payload;

  try {
    let result = yield call(update, `${baseUrl}/${id}`, data);
    if (result) {
      yield put(showEnterpriseMessage("Enterprise Modifier avec succées "));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}
function* deleteEnterprisesApi({ payload: { id, oldList } }) {
  try {
    const response = yield call(remove, `${baseUrl}/${id}`);
    const newList = oldList.filter(({ _id }) => _id !== id);
    yield put(deleteEnterpriseSuccess(newList));
    if (response) {
      yield put(showEnterpriseMessage("Enterprise Supprimer avec succées "));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

export function* addEnterprise() {
  yield takeEvery(ADD_ENTERPRISE, addNewEnterprise);
}

function* updateSingleEnterprise() {
  yield takeEvery(UPDATE_ENTERPRISE, updateEnterprise);
}
export function* getEveryEnterprise() {
  yield takeEvery(GET_ALL_ENTERPRISES, getEnterprises);
}
export function* getEveryCustomer() {
  yield takeEvery(GET_ALL_CUSTOMERS, getAllCustomers);
}
export function* getEveryProvider() {
  yield takeEvery(GET_ALL_PROVIDER, getAllProviders);
}
export function* getEnterprise() {
  yield takeEvery(GET_ENTERPRISE, getSingleEnterprise);
}
export function* deleteEnterprises() {
  yield takeEvery(DELETE_ENTERPRISES, deleteEnterprisesApi);
}
export default function* rootSaga() {
  yield all([
    fork(addEnterprise),
    fork(getEveryCustomer),
    fork(getEveryProvider),
    fork(getEveryEnterprise),
    fork(getEnterprise),
    fork(updateSingleEnterprise),
    fork(deleteEnterprises),
  ]);
}
